import jsonMask from 'json-mask';
import moment from 'moment-mini';
import { store } from '../store';
import { getEditableFieldsByEnabledModules } from '../../../shared/utils/index';

export const days = ['Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat', 'Vasárnap'];

export const months = [
  'Január',
  'Február',
  'Március',
  'Április',
  'Május',
  'Június',
  'Július',
  'Augusztus',
  'Szeptember',
  'Október',
  'November',
  'December'
];

export const formatNumber = (number, fractionDigits) =>
  isNaN(number)
    ? ''
    : number.toLocaleString('hu-HU', {
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits
      });

async function getEditableFieldsList(userRole, moduleDependencies, fieldEditRestrictions) {
  const activeModules = store.getters['module/allActiveModules'];
  const activeConfigs = store.getters['module/allActiveConfigs'];

  if (!moduleDependencies[userRole]) {
    return [];
  }
  const enabledModules = moduleDependencies[userRole].filter(
    (module) =>
      activeModules.some((key) => key === module.moduleKey) &&
      (module.configKey ? activeConfigs.some((key) => key === module.configKey) : true)
  );

  return getEditableFieldsByEnabledModules(userRole, enabledModules, fieldEditRestrictions);
}

function getMaskFromAcceptedFields(editableFields) {
  // create mask string from editable fields for json-mask
  let mask = JSON.stringify(editableFields);

  mask = mask.replaceAll('{', '');
  mask = mask.replaceAll('}', '');
  mask = mask.replaceAll('[', '(');
  mask = mask.replaceAll(']', ')');
  mask = mask.replaceAll(':', '');
  mask = mask.replaceAll('"', '');
  mask = mask.replaceAll(' ', '');

  mask = mask.slice(1);
  mask = mask.slice(0, -1);

  return mask;
}

function addEditableFields(originalEditableFields, addition) {
  const cloneOfOriginal = JSON.parse(JSON.stringify(originalEditableFields));
  for (const field of addition) {
    if (typeof field === 'string') {
      if (!cloneOfOriginal.includes(field)) {
        cloneOfOriginal.push(field);
      }
    } else {
      const key = Object.keys(field)[0];
      const index = cloneOfOriginal.findIndex(
        (field) => typeof field === 'object' && Object.keys(field)[0] === key
      );
      if (index > -1) {
        cloneOfOriginal[index][key] = addEditableFields(cloneOfOriginal[index][key], field[key]);
      } else {
        cloneOfOriginal.push(field);
      }
    }
  }
  return cloneOfOriginal;
}

function removeEditableFields(originalEditableFields, removal) {
  const cloneOfOriginal = JSON.parse(JSON.stringify(originalEditableFields));
  for (const field of removal) {
    if (typeof field === 'string') {
      const index = cloneOfOriginal.findIndex(
        (_field) => typeof _field === 'string' && _field === field
      );
      if (index > -1) {
        cloneOfOriginal.splice(index, 1);
      }
    } else {
      const key = Object.keys(field)[0];
      const index = cloneOfOriginal.findIndex(
        (_field) => typeof _field === 'object' && Object.keys(_field)[0] === key
      );
      if (index > -1) {
        cloneOfOriginal[index][key] = removeEditableFields(cloneOfOriginal[index][key], field[key]);
      }
    }
  }
  return cloneOfOriginal;
}

function changeRestrictionsByConfig(originalRestrictions, fieldEditSettings, entity, role) {
  let restrictions = JSON.parse(JSON.stringify(originalRestrictions));
  if (!fieldEditSettings || !fieldEditSettings[entity]) {
    return restrictions;
  }
  const extraRules = fieldEditSettings[entity];
  const enableList = extraRules.enable ? extraRules.enable[role] : undefined;
  if (enableList) {
    restrictions = addEditableFields(restrictions, enableList);
  }
  const disableList = extraRules.disable ? extraRules.disable[role] : undefined;
  if (disableList) {
    restrictions = removeEditableFields(restrictions, disableList);
  }
  return restrictions;
}

export async function getEditableObject(
  originObject,
  role,
  moduleDependencies,
  fieldEditRestrictions
) {
  let editableFields = await getEditableFieldsList(role, moduleDependencies, fieldEditRestrictions);

  let entity;
  if (originObject.orderId) {
    entity = 'order';
  } else if (originObject.freightId) {
    entity = 'freight';
  }
  if (entity) {
    editableFields = changeRestrictionsByConfig(
      editableFields,
      store.getters['app/fieldEditSettings'],
      entity,
      role
    );
  }

  const keyFilterMask = getMaskFromAcceptedFields(editableFields);
  const editableObject = jsonMask(originObject, keyFilterMask);

  return editableObject;
}

const daysOfWeek = {
  0: 'Vasárnap',
  1: 'Hétfő',
  2: 'Kedd',
  3: 'Szerda',
  4: 'Csütörtök',
  5: 'Péntek',
  6: 'Szombat'
};
export function Number2Day(number) {
  if (Number(number) <= 6) {
    return daysOfWeek[number];
  }
}

export function getPopUpMessagesText(messages) {
  let result = '';
  for (const message of messages) {
    result += '- ' + message;
    if (messages.indexOf(message) === messages.length - 1) {
      result += '<br><br>';
    } else {
      result += ', <br>';
    }
  }
  return result;
}

export function getRandomPostfix(date) {
  return (
    '_' +
    moment(date).format('YYMMDD') +
    '_' +
    Math.random().toString(36).substr(2, 5).toUpperCase()
  );
}

export function generateOrderId(date, factoryShortName) {
  return factoryShortName + getRandomPostfix(date);
}
