<template>
  <v-dialog
    v-if="editedObject || editedArray"
    :max-width="maxWidth"
    :value="editedObject ? editedObject : editedArray"
    @click:outside="() => (isDirty ? null : $emit('cancel'))"
    :persistent="isDirty"
  >
    <v-card>
      <v-card-title class="primary headline py-2" v-if="htmlTitle" v-html="htmlTitle">
      </v-card-title>
      <v-card-title class="primary headline py-2" v-else>
        {{ title }}
        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-switch
                @change="switchChanged"
                v-if="showSwitch"
                class="mr-0 mt-0"
                dark
                v-model="actualSwitchState"
                hide-details
                :disabled="switchDisabled"
              ></v-switch>
            </div>
          </template>
          <span>{{ switchTooltipContent }}</span>
        </v-tooltip>
      </v-card-title>

      <v-card-text id="content-wrapper">
        <v-form ref="form" v-model="isValid">
          <template>
            <slot name="default" />
          </template>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <slot name="actions">
          <v-spacer></v-spacer>
          <gli-btn
            v-if="canEdit || isProductionManager || isTransporter"
            color="primary"
            :disabled="!isValid || !isDirty || isSaving || saveDisabled"
            @click="
              () => {
                $refs.form.validate() ? $emit('save') : null;
              }
            "
          >
            <v-progress-circular
              indeterminate
              :size="22"
              :width="2"
              class="mr-1"
              v-if="isSaving"
            ></v-progress-circular>
            {{ okText }}
          </gli-btn>
          <gli-btn
            v-if="(canEdit || isProductionManager || isTransporter) && apply"
            color="primary"
            :disabled="!isValid || !isDirty || isSaving || saveDisabled"
            @click="
              () => {
                $refs.form.validate() ? $emit('apply') : null;
              }
            "
          >
            <v-progress-circular
              indeterminate
              :size="22"
              :width="2"
              class="mr-1"
              v-if="isSaving"
            ></v-progress-circular>
            {{ applyText }}
          </gli-btn>
          <gli-btn :disabled="isSaving" @click="$emit('cancel')">Mégse</gli-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.v-card__title.primary {
  color: #fff;
}

#content-wrapper {
  overflow-y: auto;
  max-height: calc(100vh - 220px);
}

#content-wrapper.v-card__text {
  padding: 20px 20px 15px 20px;
}
</style>

<script>
import { mapGetters } from 'vuex';
import { areChangesValid } from '../../../../shared/utils/compareObjects';

export default {
  name: 'GliDialog',
  props: {
    title: String,
    htmlTitle: String,
    editedObject: Object,
    editedArray: Array,
    maxWidth: { type: String, default: '850px' },
    isSaving: { type: Boolean, default: false },
    showSwitch: { type: Boolean, default: false },
    switchTooltipContent: { type: String },
    switchState: { type: Boolean, default: false },
    switchDisabled: { type: Boolean, default: false },
    saveDisabled: { type: Boolean, default: false },
    okText: { type: String, default: 'Mentés' },
    apply: { type: Boolean, default: false },
    applyText: { type: String, default: 'Alkalmaz' }
  },

  data() {
    return {
      isDirty: false,
      isValid: false,
      actualSwitchState: this.switchState,
      originalEditedObjectString: JSON.stringify(this.editedObject),
      originalEditedArrayString: JSON.stringify(this.editedArray)
    };
  },
  computed: {
    ...mapGetters('account', ['canEdit', 'isProductionManager', 'isTransporter'])
  },

  methods: {
    switchChanged() {
      this.$emit('switched', this.actualSwitchState);
    }
  },
  watch: {
    editedObject: {
      handler() {
        this.isDirty = areChangesValid(
          this.editedObject,
          JSON.parse(this.originalEditedObjectString || '{}')
        );
      },
      deep: true
    },

    editedArray: {
      handler() {
        this.isDirty = areChangesValid(
          this.editedArray,
          JSON.parse(this.originalEditedArrayString || '[]')
        );
      },
      deep: true
    }
  }
};
</script>
