import ApiService from '../services/ApiService';
import { ActionTree, MutationTree, Module } from 'vuex';
import { RootState } from '.';
import { AxiosError } from 'axios';

type State = {
  events: any[];
  eventsTotalCount: number;
  isLoading: boolean;
};

const state: State = {
  events: [],
  eventsTotalCount: 0,
  isLoading: false
};

const actions: ActionTree<State, RootState> = {
  async getEvents({ commit }, { pagination, filter }) {
    try {
      commit('setIsLoading', true);

      const { data } = await ApiService.getEvents({ pagination, filter });

      commit('setEvents', data.events);
      commit('setEventsTotalCount', data.totalCount);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.code === 'ERR_CANCELED') {
          return;
        }
      }
    }

    commit('setIsLoading', false);
  },
  // eslint-disable-next-line no-empty-pattern
  async getEventDetails({}, id) {
    const { data } = await ApiService.getEventDetails(id);
    return data.details;
  },
  clearEvents({ commit }) {
    commit('setEvents', []);
    commit('setEventsTotalCount', 0);
  }
};

const mutations: MutationTree<State> = {
  setEvents(state, events) {
    state.events = events;
  },

  setEventsTotalCount(state, count) {
    state.eventsTotalCount = count;
  },

  setIsLoading(state, isLoading: boolean) {
    state.isLoading = isLoading;
  }
};

export const events: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
