import Api from '../services/Api';

export default {
  async login(username, password) {
    return Api()
      .post('users/auth/login', { username, password }, { withCredentials: true })
      .catch(handleError);
  },

  async logout() {
    return Api().post('users/auth/logout', {}, { withCredentials: true }).catch(handleError);
  },

  async refreshToken() {
    return Api().post('users/auth/refresh', {}, { withCredentials: true }).catch(handleError);
  },

  register(user) {
    return Api().post('users/register', user).catch(handleError).then(handleResponse);
  },

  getAll() {
    return Api().get('users').catch(handleError).then(handleResponse);
  },

  getById(id) {
    return Api()
      .get('users/' + id)
      .catch(handleError)
      .then(handleResponse);
  },

  update(user) {
    return Api()
      .put('users/' + user.id, user)
      .catch(handleError)
      .then(handleResponse);
  },

  delete(id) {
    return Api()
      .delete('users/' + id)
      .catch(handleError)
      .then(handleResponse);
  }
};

function handleResponse(response) {
  if (response.status === 401) {
    // auto logout if 401 response returned from api
    exports.logout();
    location.reload(true);
  }

  return response.data;
}

function handleError(error) {
  return Promise.reject(error.response.data.message || error.response.statusText);
}
