import { PalletType } from '../types/palletType';

/**
 * Get the pallet type information from the pallet types object by the pallet name.
 * @param palletType the name of the pallet type
 * @param palletTypes the available pallet types object
 * @returns PalletType object
 */
export function checkPalletType({
  palletType,
  palletTypes
}: {
  palletType: string;
  palletTypes: Record<string, PalletType>;
}): PalletType {
  const palletTypeFromConfig = palletTypes[palletType];
  if (!palletTypeFromConfig) {
    throw new Error(`Can't find palletType for check pallet type: ${palletType}`);
  }
  return palletTypeFromConfig;
}

/**
 * Returns the heaviest pallet type from the available pallet types.
 *
 * @param availablePalletTypes - An array of available pallet types.
 * @param palletTypes - A record of all pallet types with their corresponding details.
 * @returns The heaviest pallet type.
 * @throws Error if the heaviest pallet type cannot be found.
 */
export function getHeaviestPalletType({
  availablePalletTypes,
  palletTypes
}: {
  availablePalletTypes: string[];
  palletTypes: Record<string, PalletType>;
}): PalletType {
  if (availablePalletTypes.length === 0) {
    throw new Error('No available pallet types');
  }
  let heaviestPalletType = palletTypes[availablePalletTypes[0]];
  if (!heaviestPalletType) {
    throw new Error(`Can't find palletType for heaviest pallet: ${availablePalletTypes[0]}`);
  }
  availablePalletTypes.forEach((palletType) => {
    if (palletTypes[palletType].kg > heaviestPalletType.kg) {
      heaviestPalletType = palletTypes[palletType];
    }
  });
  return heaviestPalletType;
}
