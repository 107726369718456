/**
 * If limit has a value greater than 0,
 *   it returns 1 if cost is greater than or equal to limit
 *   it returns 0 if cost is less than limit
 * It returns 1 if limit is undefined or zero or it has any other falsy value
 */
export function roundPartialCost(cost: number, limit?: number): 0 | 1 {
  if (!limit) {
    return 1;
  } else if (cost >= limit) {
    return 1;
  }
  return 0;
}
