import { jwtDecode } from 'jwt-decode';
import { store } from '.';
import { router } from '../router';
import { defineRulesFor } from '../services/ability';
import { localStorageKeys } from '../services/Api';
import AuthService from '../services/AuthService';

// TODO: create interface for state (js->ts)
const state = {
  authDisabled: false,
  status: { loggedIn: false },
  user: null,
  refreshHandler: null
};

const actions = {
  async login({ dispatch, commit }, { username, password, vm }) {
    commit('loginRequest', { username });

    try {
      const { data } = await AuthService.login(username, password);

      localStorage.setItem(localStorageKeys.token, data.token);
      localStorage.setItem(localStorageKeys.user, JSON.stringify(data._doc));

      const decoded = jwtDecode(data.token);

      dispatch('queueTokenRefresh', decoded.exp);

      await store.dispatch('module/getActiveModules');

      commit('loginSuccess', { ...data });

      if (store.getters['account/isTransporter']) {
        router.push('/freights');
      } else if (store.getters['account/isEntranceSupervisor']) {
        router.push('/timeslots');
      } else {
        router.push('/');
      }
      vm.$ability.update(defineRulesFor(store.getters['account/role']));
    } catch (error) {
      commit('loginFailure', error);
      dispatch('alert/error', error, { root: true });
    }
  },

  async logout({ commit }) {
    clearTimeout(state.refreshHandler);

    await AuthService.logout();
    localStorage.removeItem(localStorageKeys.token);
    localStorage.removeItem(localStorageKeys.user);
    commit('logout');
    if (router.currentRoute.path !== '/login') {
      router.push('/login');
    }
  },

  async refreshToken({ state, dispatch, commit }) {
    if (state.authDisabled) {
      return;
    }

    const { data } = await AuthService.refreshToken();
    if (data.token) {
      localStorage.setItem(localStorageKeys.token, data.token);
      const decoded = jwtDecode(data.token);

      dispatch('queueTokenRefresh', decoded.exp);
      if (!state.status.isLoggedIn) {
        const user = JSON.parse(localStorage.getItem(localStorageKeys.user));
        commit('loginSuccess', {
          // TODO remove annoying _doc
          _doc: user
        });
      }
    } else {
      dispatch('logout');
    }
  },

  queueTokenRefresh({ dispatch, commit }, expiration) {
    // timeout is set to 1 minute (or less) before expiration
    const timeout = new Date(expiration * 1000) - Date.now() - 60000;
    const refreshHandler = setTimeout(
      async () => {
        dispatch('refreshToken');
      },
      timeout > 0 ? timeout : 0
    );

    commit('queueTokenRefresh', { refreshHandler });
  },

  readLocalStorage({ dispatch, commit }) {
    const token = localStorage.getItem(localStorageKeys.token);
    if (token) {
      const decoded = jwtDecode(token);
      const expDate = new Date(decoded.exp * 1000).valueOf();
      const now = Date.now();
      if (expDate > now) {
        const user = JSON.parse(localStorage.getItem(localStorageKeys.user));
        dispatch('queueTokenRefresh', decoded.exp);
        commit('loginSuccess', {
          // TODO remove annoying _doc
          _doc: user
        });
      } else {
        // if token expired, logout
        dispatch('logout');
      }
    } else {
      // if no token, logout
      dispatch('logout');
    }
  },

  setCanEditByConfigException({ commit }, canEditByException) {
    commit('setCanEditByConfigException', canEditByException);
  }
};

const getters = {
  isUserLoggedIn: (state) => {
    return state.status && state.status.loggedIn;
  },
  isSuperAdmin: (state) => {
    return (
      state.status && state.status.loggedIn && state.user && state.user._doc.role === 'superadmin'
    );
  },
  isAdmin: (state) => {
    return (
      state.status &&
      state.status.loggedIn &&
      state.user &&
      (state.user._doc.role === 'admin' || state.user._doc.role === 'superadmin')
    );
  },
  isLogisticsManager: (state, getters) =>
    getters.isUserLoggedIn && state.user && state.user._doc.role === 'logistics manager',
  isTransporter: (state, getters, rootState) => {
    return (
      state.status &&
      state.status.loggedIn &&
      state.user &&
      (state.user._doc.role === rootState.app.constants.ROLES.TRANSPORTER_MANAGER ||
        state.user._doc.role === rootState.app.constants.ROLES.TRANSPORTER_VIEWER)
    );
  },
  isProductionManager: (state, getters, rootState) => {
    return (
      state.status &&
      state.status.loggedIn &&
      state.user &&
      state.user._doc.role === rootState.app.constants.ROLES.PRODUCTION_MANAGER
    );
  },

  isEntranceSupervisor: (state) => {
    return (
      state.status &&
      state.status.loggedIn &&
      state.user &&
      state.user._doc.role === 'entrance supervisor'
    );
  },

  authDisabled: (state) => state.authDisabled,

  canEdit: (state, getters) =>
    getters.isAdmin || getters.isLogisticsManager || state.authDisabled || state.canEditByException,

  displayName: (state) => {
    if (!state.status.loggedIn) {
      return undefined;
    }
    const user = state.user?._doc;
    return user.username + ' (' + user.role + ')';
  },

  role: (state) => {
    return state.user?._doc.role;
  },

  user: (state) => state.user?._doc,

  userId: (state) => state.user?._doc.id
};

const mutations = {
  disableAuth(state) {
    state.authDisabled = true;
  },
  loginRequest(state, user) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  loginSuccess(state, user) {
    state.status = { loggedIn: true, registering: false, updating: false };
    state.user = user;
  },
  loginFailure(state) {
    state.status = { isLoggedIn: false };
    state.user = null;
  },
  logout(state) {
    state.status = { isLoggedIn: false };
    state.user = null;
  },
  queueTokenRefresh(state, payload) {
    state.refreshHandler = payload.refreshHandler;
  },
  setCanEditByConfigException(state, canEditByException) {
    state.canEditByException = canEditByException;
  }
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
