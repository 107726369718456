import axios from 'axios';
import qs from 'qs';
import config from '../helpers/configProvider';
import { store } from '../store/index';

export const localStorageKeys = {
  token: 'token',
  user: 'user'
};
const basePath = config.getEnv(config.enum.VUE_APP_BASE_PATH);
if (basePath) {
  localStorageKeys.token = basePath.replace('/', '') + '_token';
  localStorageKeys.user = basePath.replace('/', '') + '_user';
}

export default ({ useResponseInterceptors } = { useResponseInterceptors: true }) => {
  const api = axios.create({
    baseURL: config.getEnv(config.enum.VUE_APP_API_SERVICE_URL) || `http://localhost:8081`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    paramsSerializer: (params) =>
      qs.stringify(params, {
        encode: false,
        skipNulls: false,
        strictNullHandling: true
      })
  });

  // Add a request interceptor
  api.interceptors.request.use((config) => {
    if (!store.getters['account/authDisabled']) {
      const token = localStorage.getItem(localStorageKeys.token);
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  });

  if (!useResponseInterceptors) {
    return api;
  }

  // Add a response interceptor
  api.interceptors.response.use(
    (config) => config,
    async (error) => {
      // eslint-disable-next-line no-magic-numbers
      if (401 === error.response?.status) {
        await store.dispatch('account/logout');
      } else {
        if (
          error.config?.url.indexOf('/check-processing') < 0 &&
          error.config?.url.indexOf('/check-changes') < 0
        ) {
          // parsing error from pdf generator
          if (error.response?.config?.responseType === 'arraybuffer') {
            const decoder = new TextDecoder('utf-8');
            error.response.data = JSON.parse(decoder.decode(new Uint8Array(error.response.data)));
          }

          if (error.response.status === 504) {
            store.dispatch('alert/error', 'Időtúllépés miatt nem érkezett válasz a szervertől!');
          } else {
            store.dispatch(
              'alert/error',
              error.response?.data?.details ||
                error.response?.data?.message ||
                'Hiba történt, próbálja újra!'
            );
          }
        }
        return Promise.reject(error);
      }
    }
  );

  return api;
};
