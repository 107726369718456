// ! If you would like to understand the object comparison
// ! start it on the bottom with the exported function.
// ! In every function the first parameter will be the newest object (or it's values)
// ! and the second will be the original object (or it's values).
// ! Sometimes there are checks for null because in ts: typeof null = 'object'.

/**
 * The 'areChangesImportant' function has 2 parameters.
 * Both of them has the same type unless one of them is undefined.
 * This function is used to compare 'string | number | Date | boolean | undefined' values
 * and return
 *    -true: if there is any truthy changes
 *    -false: otherwise (return false means there are only falsy changes)
 * The function will tell if the two params are different or not.
 */
function areChangesImportant(
  value1: string | number | Date | boolean | undefined,
  value2: string | number | Date | boolean | undefined
): boolean {
  if (!value1 !== !value2) {
    if (value1 !== false || value2 !== undefined) {
      // if(value1 === false) --> It is a checkbox which is not checked
      // if a checkbox on a screen is not checked yet then maybe it's value is undefined
      // so we have to check the false-->true and undefined-->true-->false cases
      return true;
    } else {
      return false;
    }
  } else if (!value1 && !value2) {
    return false;
  } else if (value1 !== value2) {
    /**
     * If value1 and 2 match the following regexp that means they are dates.
     * We only have to check the year+month+day values
     * because sometimes hours above 22:00 are rounded to the next day.
     */
    if (
      typeof value1 === 'string' &&
      typeof value2 === 'string' &&
      value1 !== undefined &&
      value2 !== undefined &&
      new RegExp('[1-9]{1}[0-9]{3}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}').test(value1) &&
      new RegExp('[1-9]{1}[0-9]{3}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}').test(value2)
    ) {
      let val1 = new Date(value1);
      let val2 = new Date(value2);
      val1 = new Date(val1.getFullYear(), val1.getMonth(), val1.getDate());
      val2 = new Date(val2.getFullYear(), val2.getMonth(), val2.getDate());
      const sameDate = new Date(val1).getTime() === new Date(val2).getTime();
      return !sameDate;
    }
    return true;
  }
  return false;
}

/**
 * The 'isEveryPropertyFalsy' function has one parameter.
 * It tells if the object only has falsy properties.
 * Returns:
 *  - true: every property false
 *  - false: minimum 1 property is not falsy (return false means the object has truthy property)
 *
 * This function is important because in the 'areChangesValidOnDifferentTypes' function
 * we only checks if Object.values(someObject).length === 0 -->
 * --> if we have an object which has a property = undefined
 * then the the Object.values(someObject) result will be [undefined] which has a length of 1
 *
 * for example:
 * someObject = {
 *  name: undefined,
 *  age: undefined
 * }
 *
 * Object.values(someObject) ==> [undefined, undefined]
 */
function isEveryPropertyFalsy(object: object): boolean {
  if (!object) {
    return true;
  }
  for (const key of Object.keys(object)) {
    if (object[key as keyof typeof object]) {
      return false;
    }
  }
  return true;
}

/**
 * The 'areChangesValidOnDifferentTypes' has 2 property which has different types.
 * Usually we can get into this function if the 2 compared objects has properties like this:
 *
 * object1 = {
 *  prop1: 'exampleString'
 * }
 * object2 = {
 *  prop1: undefined
 * }
 *
 * This function is made to check
 * if one of the 2 properties has truthy and the other one has falsy value.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function areChangesValidOnDifferentTypes(newValue: any, oldValue: any): boolean {
  /**
   * Check if one of the two properties is truthy.
   * If not then return false.
   *
   * If one of them is truthy then we have to check if it is an object
   * and if it is then we have to check if it has minimum 1 truthy value.
   *
   * If typeof newValue = 'object'
   * then it is truthy so we have to check if it has truthy value. (1st else if)
   * If typeof oldValue = 'object'
   * then it is truthy so we have to check if it has truthy value. (2nd else if)
   *
   * ! This function expects that one of the 2 props is truthy and the other one if falsy
   */
  if (!newValue && !oldValue) {
    return false;
  } else if (
    // step into it if newValue is truthy and expect oldValue to be falsy
    typeof newValue === 'object' &&
    newValue !== null &&
    (Object.values(newValue).length === 0 || isEveryPropertyFalsy(newValue))
  ) {
    return false;
  } else if (
    // step into it if oldValue is truthy and expect newValue to be falsy
    typeof oldValue === 'object' &&
    oldValue !== null &&
    (Object.values(oldValue).length === 0 || isEveryPropertyFalsy(oldValue))
  ) {
    return false;
  } else {
    return true;
  }
}

/**
 * The 'areChangesValid' function has 2 props. Firstly the 1st: new object, 2nd: old object,
 * then because of the recursivity the 1st: new object's value, 2nd: old object's value.
 * Because of the recursivity the 2 params could have a lot of types during the process.
 *
 * ! The order of the params (new, old) is important because we loop through the 1st one
 * ! and checks if the 2nd contains the same.
 * ! This order is a must because if we loop through the old one
 * ! then we only check the already existing props
 * ! but in this case we miss the props we added to the edited object.
 *
 * *This functions can handle changes only if we modify already existing properties or add new ones.
 * * If there will be any case that we can remove properties from an object
 * * and it causes truthy meaningful changes then we have to do a refactor.
 *
 * It returns:
 *    - true: if the 2 objects (edited object, original object) have any truthy changes
 *    - false: if the 2 objects (edited object, original object) haven't got any truthy changes
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function areChangesValid(newValue: any, oldValue: any): boolean {
  try {
    /**
     * If the new and old values are not objects and not arrays
     * then they are simple values (like number, etc.)
     * so we compare them (with the areChangesImportant function) if there is any truthy changes.
     */
    if (
      typeof newValue !== 'object' &&
      !Array.isArray(newValue) &&
      typeof oldValue !== 'object' &&
      !Array.isArray(oldValue)
    ) {
      return areChangesImportant(newValue, oldValue);
    }
    /**
     * If the the value has different types then we use 'areChangesValidOnDifferentTypes' method
     * to check if there is any truthy changes between them.
     */
    if (typeof newValue !== typeof oldValue) {
      return areChangesValidOnDifferentTypes(newValue, oldValue);
    } else if (Array.isArray(newValue) && Array.isArray(oldValue)) {
      /**
       * If the two values are arrays then we loop through them and recursively call this function.
       */
      if (newValue.length !== oldValue.length) {
        return true;
      }
      /**
       * Check if the two arrays only include strings or numbers.
       * object.stringify() can not be used here because it can't handle the cases below:
       *    - the order of the keys are not ordered --> on different orders string check fails
       *    - some value in newValue = null --> the same value in oldValue = undefined
       *        - this means the change was not meaningful but string check fails
       */
      let isEveryStringOrNumberIncluded = true;
      for (const element of newValue) {
        if (typeof element === 'string' || typeof element === 'number') {
          if (!oldValue.includes(element)) {
            return true;
          }
        } else {
          isEveryStringOrNumberIncluded = false;
          break;
        }
      }
      if (isEveryStringOrNumberIncluded) {
        return false;
      }
      // An array to store the result of the values comparisons.
      const returnValue: boolean[] = [];
      for (let i = 0; i < newValue.length; i++) {
        returnValue.push(areChangesValid(newValue[i], oldValue[i]));
      }
      // Check if there is any truthy changes
      // because in that case there is at least 1 important change.
      return returnValue.includes(true);
    } else if (typeof newValue === 'object' && typeof oldValue === 'object') {
      /**
       * If the two values are objects then we loop through them and recursively call this function.
       */
      if (newValue === null && oldValue === null) {
        return false;
      }
      // An array to store the result of the values comparisons.
      const returnValue: boolean[] = [];
      for (const key in newValue) {
        returnValue.push(areChangesValid(newValue[key], oldValue[key]));
      }
      // Check if there is any truthy changes
      // because in that case there is at least 1 important change.
      return returnValue.includes(true);
    }
    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return true;
  }
}
