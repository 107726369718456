/* eslint-disable no-unused-vars */
import { store } from '../../store';
import { ExcelImportValidation } from '../../../../shared/utils/enums';

export type FilterEntityTypes =
  | 'pickup'
  | 'freight'
  | 'order'
  | 'freightBill'
  | 'operativeStatistics'
  | 'costReportStatistics'
  | 'capacityStatistics'
  | 'financialStatistics';
export enum DateFilterTypes {
  today = 'today',
  this_week = 'this_week',
  this_month = 'this_month',
  this_year = 'this_year'
}
export type DefaultFilters = Record<FilterEntityTypes, Record<string, any>>;

export type ExcelConfigHeader = {
  excelHeader: string;
  logisticsField: string;
  validations?: ExcelImportValidation[];
};

export type Config = {
  view: {
    id: string;
    title: string;
    mode: string;
    favicon: string;
    logo: string;
    largeLogo: string;
    theme: {
      primary: string;
      secondary: string;
      accent: string;
      error: string;
      info: string;
      success: string;
      warning: string;
      background: string;
      sidepanel: string;
      tollRoadLayer: string;
      weightLimitZoneLayer: string;
    };
  };
  mainVehicle: string;
  defaultFilters: DefaultFilters;
  colors: string[];
  disableAuth: boolean;
  testOrdersJson?: any;
  units: {
    [key: string]: {
      extendedNames: string[];
      displayName: string;
      unitForPDF: string;
      columnHeaderName: string;
      isOnlyInteger: boolean;
    };
  };
  productCategory: string[];
  freightCostTypes: {
    [key: string]: string;
  };
  palletTypes: Record<string, { kg: number; dimensions: [number, number] }>;
  partialPalletRoundingLimit: number;
  xlsxHeaders?: Record<
    string,
    { requiredHeaders?: ExcelConfigHeader[]; optionalHeaders?: ExcelConfigHeader[] }
  >;
  logisticsPlannerType: typeof store.state.app.enums.LogisticsPlannerType;
  fieldEditSettings: Record<string, any>;
};
